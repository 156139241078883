import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../store/auth-context";

const StatesDropdown = ({ onChange }) => {
  const authCtx = useContext(AuthContext);
  const [statesState, setStatesState] = useState({
    isLoading: false,
    data: [],
  });

  const fetchTypes = useCallback(async () => {
    const response = await authCtx.request(
      "GET",
      `${process.env.REACT_APP_Prep_Api_Host}utility/state-list`
    );

    if (response && response.data) {
      setStatesState((previous) => ({ ...previous, data: response.data }));
    }
  }, [authCtx]);

  useEffect(() => {
    const getTypes = async () => {
      await fetchTypes();
    };
    getTypes();
  }, [fetchTypes]);

  return (
    <FormControl fullWidth>
      <InputLabel id="store-type-select-label">State</InputLabel>
      <Select labelId="store-type-label" id="store-type-select" label="Type">
        {statesState.data.map((type) => (
          <MenuItem value={type.stateCode}>{type.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StatesDropdown;
